<template>
  <section class="mx-md-9 mx-sm-6">
    <!-- title -->
    <div class="my-6 d-flex flex-column">
      <span
        class="text-h5 secondary--text"
        v-text="'Proceso de compra con reserva'"
      />
      <span v-text="'Ejercicio ' + anioFiscal" />
    </div>

    <!-- action create -->
    <div
      v-if="haveRole('ROLE_PROCESOS_RESERVA')"
      class="mt-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
    >
      <!-- boton que redirecciona a la vista de crear proceso de compra con reserva -->
      <v-btn
        class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-0"
        color="secondary"
        @click="$router.push({ name: 'proceso-compra-reserva-crear-proceso' })"
      >
        Agregar proceso con reserva
      </v-btn>
    </div>

    <!-- filtros -->
    <v-row class="mt-6">
      <!-- filtro por codigo o nombre -->
      <v-col cols="12" sm="6">
        <v-text-field
          clearable
          dense
          hide-details
          label="Código o Nombre"
          maxlength="150"
          outlined
          v-model="filter_cod_nom_var"
        />
      </v-col>

      <!-- filtros por institucion -->
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="ctl_institucion_var"
          clearable
          dense
          hide-details
          item-text="nombre"
          item-value="id"
          label="Institución"
          outlined
          v-model="filter_id_institucion_var"
        />
      </v-col>

      <!-- filtros por modalidad -->
      <v-col cols="12" md="4" sm="7">
        <v-select
          :items="ctl_modalidad_var"
          clearable
          dense
          hide-details
          item-text="nombre"
          item-value="id"
          label="Modalidad de compra"
          outlined
          v-model="filter_id_modalidad_var"
        />
      </v-col>

      <!-- filtros por estado -->
      <v-col cols="12" md="3" sm="5">
        <v-select
          :items="ctl_estado_var"
          clearable
          dense
          hide-details
          item-text="nombre"
          item-value="id"
          label="Estado"
          outlined
          v-model="filter_id_estado_var"
        />
      </v-col>

      <!-- filtros por fecha -->
      <v-col cols="12" md="5" sm="8">
        <div class="d-flex flex-column flex-sm-row align-sm-center">
          <v-menu
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="filter_fecha_desde_var.menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="FormatDateFtn(filter_fecha_desde_var.value)"
                @click:clear="filter_fecha_desde_var.value = null"
                append-icon="mdi-calendar"
                class="mb-6 mb-sm-0 pr-sm-3"
                clearable
                dense
                hide-details
                label="Desde"
                outlined
                placeholder="Seleccione una fecha"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :max="filter_fecha_hasta_var.value"
              @input="filter_fecha_desde_var.menu = false"
              no-title
              scrollable
              v-model="filter_fecha_desde_var.value"
            />
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="filter_fecha_hasta_var.menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="filter_fecha_desde_var.value == null"
                :value="FormatDateFtn(filter_fecha_hasta_var.value)"
                append-icon="mdi-calendar"
                class="mb-6 mb-sm-0 px-sm-3"
                clearable
                dense
                hide-details
                label="Hasta"
                outlined
                placeholder="Seleccione una fecha"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :min="filter_fecha_desde_var.value"
              @input="filter_fecha_hasta_var.menu = false"
              no-title
              scrollable
              v-model="filter_fecha_hasta_var.value"
            />
          </v-menu>

          <!-- boton buscar -->
          <v-btn
            color="secondary"
            class="ml-sm-3 text-no-style"
            @click="FilterListProcFtn"
            dark
          >
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            class="ml-sm-3 mt-6 mt-sm-0 text-no-style"
            @click="CleanFiltersFtn"
            outlined
          >
            <v-icon color="secondary"> mdi-broom </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- table -->
    <data-table-component
      :headers="HEADERS_CONST"
      :items="list_process_var"
      mobile_breakpoint="1300"
      table_title="Listado de procesos con reserva"
      :total_registros="list_process_pag.total_rows"
      @paginar="PaginateFtn"
      v-models:pagina="list_process_pag.page"
      v-models:select="list_process_pag.per_page"
    >
      <!-- slot de fecha de inicio de proceso -->
      <template v-slot:[`item.fecha_inicio_proceso`]="{ item }">
        {{ FormatDateFtn(item.fecha_inicio_proceso) }}
      </template>

      <!-- slot de fecha de contratacion -->
      <template v-slot:[`item.fecha_contratacion`]="{ item }">
        {{ FormatDateFtn(item.fecha_contratacion) }}
      </template>

      <!-- slot de suma de mercancia -->
      <template v-slot:[`item.suma_mercancia`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.suma_mercancia)
        }}
      </template>

      <!-- slot de acciones -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              :disabled="item.seguimiento_proceso.id !== 1 || item?.EstadoProceso?.id == 1"
              @click="
                $router.push({
                  name: 'proceso-compra-reserva-editar-proceso',
                  params: { id_proceso: item.id },
                })
              "
            >
              <v-icon color="secondary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span> Editar </span>
        </v-tooltip>
      </template>
    </data-table-component>
  </section>
</template>

<script>
// importacion de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";

// importacion de librerias
import { mapState } from "vuex";

export default {
  name: "listProcesosView",

  components: {
    DataTableComponent,
  },

  data: () => ({
    //constantes
    HEADERS_CONST: [
      {
        text: "Código",
        value: "codigo_proceso",
        align: "center",
      },
      {
        text: "Proceso",
        value: "nombre_proceso",
        align: "center",
      },
      {
        text: "Modalidad de compra",
        value: "forma_contratacion.nombre",
        align: "center",
      },
      {
        text: "Institución",
        value: "Institucion.nombre",
        align: "center",
      },
      {
        text: "Mes programado",
        value: "fecha_inicio_proceso",
        align: "center",
      },
      {
        text: "Fecha solicitado",
        value: "fecha_contratacion",
        align: "center",
      },
      {
        text: "Monto programado ($)",
        value: "suma_mercancia",
        align: "center",
      },
      {
        text: "Estado",
        value: "EstadoProceso.nombre",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    FILTERS_SNAPSHOT_CONST: {},

    // variables
    filter_cod_nom_var: null,
    filter_id_institucion_var: null,
    filter_id_modalidad_var: null,
    filter_id_estado_var: null,
    filter_fecha_desde_var: {
      menu: false,
      value: null,
    },
    filter_fecha_hasta_var: {
      menu: false,
      value: null,
    },

    list_process_var: [],
    list_process_pag: {
      page: 1,
      per_page: 10,
      total_rows: 0,
    },

    ctl_institucion_var: [],
    ctl_modalidad_var: [],
    ctl_estado_var: [
      {
        id: 1,
        nombre: "Activo",
      },
      {
        id: 4,
        nombre: "En borrador",
      },
    ],
  }),

  computed: {
    ...mapState(["userInfo", "anioFiscal"]),
  },

  methods: {
    // <-- ----------------- FUNCIONES REGULARES ----------------- -->

    /**
     * @description Funcion para formatear fecha
     * @param {Date} date_param
     * @returns {String} Fecha formateada
     */
    FormatDateFtn(date_param) {
      if (date_param != null) {
        return this.moment(date_param).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    /**
     * @description Funcion para limpiar los filtros
     */
    CleanFiltersFtn() {
      this.filter_cod_nom_var = null;
      this.filter_id_institucion_var = null;
      this.filter_id_modalidad_var = null;
      this.filter_id_estado_var = null;
      this.filter_fecha_desde_var.value = null;
      this.filter_fecha_hasta_var.value = null;

      this.FILTERS_SNAPSHOT_CONST = {};

      this.FetchListProcFtn({ page: 1, per_page: 10 });
    },

    /**
     *
     * @description Funcion para paginar la lista de procesos
     * @param { Number } cantidad_por_pagina
     * @param { Number } pagina
     */
    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListProcFtn({
        per_page: cantidad_por_pagina,
        page: pagina,
      });
    },

    /**
     * @description Funcion filtrar la lista de procesos, validar los campos y preparar payload
     */
    FilterListProcFtn() {
      if (
        this.filter_fecha_desde_var.value != null &&
        this.filter_fecha_hasta_var.value == null
      ) {
        this.temporalAlert({
          message: "Debe seleccionar una fecha hasta",
          show: true,
          type: "warning",
        });
        return;
      }

      const filters_scp_var = {
        nombre: this.filter_cod_nom_var || undefined,
        id_institucion: this.filter_id_institucion_var || undefined,
        id_forma_contratacion: this.filter_id_modalidad_var || undefined,
        id_estado_proceso: this.filter_id_estado_var || undefined,
        fecha_desde: this.filter_fecha_desde_var.value || undefined,
        fecha_hasta: this.filter_fecha_hasta_var.value || undefined,
      };

      const isAllUndefined = Object.values(filters_scp_var).every(
        (x) => x === undefined
      );

      if (isAllUndefined) {
        this.temporalAlert({
          message: "Debe ingresar al menos un filtro",
          show: true,
          type: "warning",
        });
        return;
      }

      this.FILTERS_SNAPSHOT_CONST = filters_scp_var;

      this.FetchListProcFtn({ page: 1, per_page: 10 });
    },

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    /**
     * @description Funcion para obtener los datos de catalogo de instituciones (API)
     * @async
     */
    async FetchCtlInstnFtn() {

      const { data, status } = await this.services.Usuarios.getInstituciones();
      if (status == 200) {
        this.ctl_institucion_var = data;
      }

    },

    /**
     * @description Funcion para obtener los datos de catalogo de modalidades de compra (API)
     * @async
     */
    async FetchCtlModalidadFtn() {

      const { data, status } =
        await this.services.PacProcesos.getProcesoModalidad();
      if (status == 200) {
        this.ctl_modalidad_var = data;
      }

    },

    /**
     * @description Funcion para obtener el listado de procesos (API)
     * @async
     */
    async FetchListProcFtn(filter_param = {}) {
      if (typeof filter_param !== "object") {
        console.error("El argumento debe ser un objeto");
        return;
      }


      const { data, status, headers } =
        await this.services.Paac.getListProcesos({
          ...this.FILTERS_SNAPSHOT_CONST,
          id_tipo_proceso: 2,
          ...filter_param,
        });

      if (status == 200) {
        this.list_process_var = data.procesos;
        this.list_process_pag.total_rows = Number(headers.total_rows);
        this.list_process_pag.page = Number(headers.page);
        this.list_process_pag.per_page = Number(headers.per_page);
      }

    },
  },

  watch: {
    "filter_fecha_desde_var.value"(val) {
      if (val == null) {
        this.filter_fecha_hasta_var.value = null;
      }
    },
  },

  created() {
    this.FetchCtlInstnFtn();
    this.FetchCtlModalidadFtn();
    this.FetchListProcFtn();
  },
};
</script>
<!-- Vista de listado de proceso de compra con reserva -->